<template>
  <div>
    <!-- <div style="margin-left: 180px; padding-bottom: 4%">
      正在前往微信支付...
    </div> -->
    <div class="back" v-if="show == 'goBack'">
      <span style="font-size: 13pt">支付已完成，正在返回，请稍后...</span>
    </div>
    
    <div class="main" v-else>
       <div class="payTitle">
         <div class="payLogo">
           <img src="../../assets/img/index/titleLogo.png" style="width:100%;height:100%;">
          </div>
          <div class="payBar"><span>收银台</span></div>
     </div>
      <div style="padding-bottom: 2%; width: 800px;margin-top:3%;">
        <span style="position: relative; left: -23%; display: inline-block"
          >订单提交成功，请尽快支付!</span
        >
        <div
          style="
            position: relative;
            left: 25%;
            width: 200px;
            display: inline-block;
            text-align: right;
          "
        >
          <span
            >应付金额
            <span
              style="color: rgb(227, 22, 19); font-size: 18px; font-weight: 550"
              >{{ totalMoney }}</span
            >
            元</span
          >
        </div>
      </div>
      <div class="pay">
        <div style="display: inline-block">
          <!-- <div  class="payCode"> -->
          <div class="payCode" ref="qrCodeUrl"></div>
          <!-- </div> -->
          <div class="payTips">
            <div
              style="
                width: 40px;
                height: 40px;
                margin-left: 10px;
                display: inline-block;
              "
            >
              <img
                src="../../assets/img/pay/pc_icon_icon-red.png"
                style="width: 100%; height: 100%"
              />
            </div>
            <div
              style="
                width: 125px;
                display: inline-block;
                vertical-align: top;
                margin-left: 5px;
                margin-top: -3px;
              "
            >
              <span style="color: #fff">请使用微信扫一扫扫描二维码支付</span>
            </div>
          </div>
        </div>
        <div class="payPhoto">
          <img
            src="../../assets/img/pay/pc_icon_phone-bg.png"
            style="width;100%;height:100%;"
          />
        </div>
      </div>
    </div>
    <!-- {{content}} -->
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      content: "",
      orderNumber: "",
      totalMoney: "",
      timer: null,
      showTimer: null,
      show: "",
    };
  },
  methods: {
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.content, // 需要转换为二维码的内容
        width: 280,
        height: 280,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    }, //
    queryIsPay() {
      var getData = {};
      getData.orderNumber = this.orderNumber;
      this.$api.notice.querryPayStatus(getData).then((res) => {
        console.log("支付返回查询", res);
        let response = res.data.data.msg;
        if (response != undefined) {
          this.show = "goBack";
          this.showTimer = setTimeout(() => {
            console.log("8888");
            this.$router.push({ path: "/user" });
          }, 2000);
        }

      });
    },
  },
  mounted() {
    this.creatQrCode();
    // this.queryIsPay()
  },

  created() {
    this.content = this.$route.query.content;
    this.orderNumber = this.$route.query.orderNumber;
    console.log("***", this.$route.query.orderNumber);
    this.totalMoney = this.$route.query.totalMoney;
    this.timer = setInterval(() => {
      this.queryIsPay();
    }, 2000);
  },
  beforeDestroy() {
    if (this.timer) {
      //如果定时器还在运行 或者直接关闭，不用判断
      clearInterval(this.timer); //关闭
    }
    // if (this.showTimer) {
    //   //如果定时器还在运行 或者直接关闭，不用判断
    //   clearInterval(this.showTimer); //关闭
    // }
  },
};
</script>

<style scoped>
.pay {
  width: 800px;
  height: 500px;
  /* background: #47C297; */
  /* margin-left: 26%; */
  /* margin-left: 200px; */
  /* margin-top: 6%; */
  padding-top: 5%;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 15px 0 rgba(0, 0, 0, 0.19);
}
.payCode {
  width: 300px;
  height: 300px;
  /* background: lightpink; */
  margin-left: 0%;
  border: 1px solid rgb(221, 221, 221);
  padding-left: 9px;
  padding-top: 9px;
}
.payPhoto {
  width: 250px;
  height: 380px;
  /* background: #79c1d2; */
  display: inline-block;
  vertical-align: top;
  margin-left: 10%;
}
.payTips {
  width: 300px;
  margin-top: 15px;
  padding-top: 10px;
  height: 65px;
  background: rgb(255, 118, 116);
}
.main {
  width: 850px;
  height: auto;
  margin-top: 2%;
  /* padding-left: 40px; */
  /* background: rgb(255, 118, 116); */
  display: inline-block;
  vertical-align: top;
}
.back {
  margin-top: 18%;
}
.payTitle{
  text-align: left;
}
.payLogo{
  width: 100pt;
  height: 41pt;
  /* background: lightpink; */
  display: inline-block;
}
.payBar{ 
display: inline-block;
/* background: lightpink; */
font-size: 13pt;
color: rgb(54, 53, 53);
font-family: Arial,Verdana;
font-weight: 550;
opacity: 0.7;
vertical-align: bottom;
margin-left: 2%;
}
</style>


